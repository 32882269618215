import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otp-varification',
  templateUrl: './otp-varification.component.html',
  styleUrls: ['./otp-varification.component.css']
})
export class OtpVarificationComponent implements OnInit {

  otp: any;
  resend: boolean = true
token : any
  constructor(public service: ServiceService, public router: Router) { }

  ngOnInit(): void {
  }

  onOtpChange(otp: any) {
    this.otp = otp;
  }

  otpVarification() {
    let url = `admin/verifyOTP`
    let data = {
      email: localStorage.getItem('email'),
      otp: this.otp
    }
    this.service.showSpinner();
    this.service.postApiFunc(url, data, 0).subscribe(res => {

      if (res['responseCode'] == 200) {
        this.router.navigate(['/reset-password'],{queryParams : {token : res.result.token}})
        this.service.toasterSucc(res.responseMessage)
       
        this.service.hideSpinner();
      } 
      else {
         this.service.toasterErr(res.responseMessage)
      }

    }, err => {

      this.service.hideSpinner();
      this.service.toasterErr(err.error.responseMessage)
        
    });
  }

  onReset() {
    let url = `admin/resendOTP`
    let data = {
      email: localStorage.getItem('email')
    }
    this.service.putApi(url, data, 0).subscribe(res => {

      if (res['responseCode'] == 200) {
        this.router.navigate(['/otp'])
        this.service.toasterSucc('Successfully sent email to your registered email id')
      } else {
        this.service.toasterErr(res.responseMessage)
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.responseMessage)
      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Email address is not registered');
      }
    });
  }

}
